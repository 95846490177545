<template>
    <Modal :title="title" v-model="isShowModal">
        <template v-slot:modal-content>
            建議使用電腦瀏覽此頁面，讓您有更足夠的空間查看報表資訊。
        </template>
        <template v-slot:modal-bottom>
            <Button
                buttonWord="知道了"
                buttonStyle="blue"
                @click="isShowModal = false"
            />
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'ProductMobileAlertModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        }
    },
    watch: {
        isShowModal: function () {
            this.$emit('update:modelValue', this.isShowModal)
        },
        modelValue: function () {
            this.isShowModal = this.modelValue
        }
    },
    data() {
        return {
            isShowModal: this.modelValue,
            title: '閱覽提醒'
        }
    },
    mounted() {
        if (this.$isMobile() && !this.$isMobileHorizontal()) {
            const body = document.querySelector('body')
            setTimeout(() => {
                this.$addAllClass(body, 'not-scroll')
            }, 500)
        }
    }
}
</script>
