<template>
    <div>
        <ViewTitle title="商品資料庫 | 受理商品利益估算" />
        <ProductFilter ref="filter" @show-table-data="showDataTable">
            <template v-slot:filter-bottom="{ payload }">
                <div class="filter-bottom">
                    <Button
                        buttonWord="套用"
                        buttonStyle="blue"
                        iconClass="big-icon search-icon"
                        @click="showDataTable(payload)"
                    />
                </div>
            </template>
        </ProductFilter>
        <ProductTable
            excelTitle="商品資料庫_受理商品利益估算"
            :filterTitles="filterTitles"
            :listData="listData"
            :isLoading="isTableLoading"
            :totalData="totalData"
        />
        <ProductMobileAlertModal v-model="isShowMobileAlert" />
    </div>
</template>

<script>
import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import ProductFilter from '../../containers/product/ProductFilter.vue'
import ProductTable from '@/containers/product/ProductTable.vue'
import Button from '../../components/Button.vue'
import ProductMobileAlertModal from '@/containers/product/ProductMobileAlertModal.vue'
import { getProductReceiveAPI } from '@/assets/javascripts/api'
import { productReceiveFilterTitles } from '@/assets/javascripts/product/setting'

export default {
    name: 'ProductReceive',
    components: {
        ViewTitle,
        ProductFilter,
        ProductTable,
        Button,
        ProductMobileAlertModal
    },
    methods: {
        showDataTable: async function () {
            /* 使用子元件檢查是否所有輸入框都有輸入完畢 */
            this.isAllChecked = this.$refs.filter.checkAllList()
            if (!this.isAllChecked) return
            //取得 payload
            let originData = this.$refs.filter.$data.selectedList
            let payloadList = originData.map((list) => {
                const opt = list.options
                return {
                    productId: parseInt(list.productId),
                    fyp: parseInt(opt.price.replace(/,/g, '')),
                    yPeriod: parseInt(opt.expiration),
                    insAge: parseInt(opt.age),
                    uid: list.uuid
                }
            })
            this.isTableLoading = true
            this.$showLoading()
            try {
                const res = await this.$getResponse(
                    payloadList,
                    {
                        apiAlias: 'list',
                        apiName: '此報表'
                    },
                    getProductReceiveAPI
                )
                this.listData = res.data.products
                this.totalData = _.omit(res.data, 'products')
            } catch (e) {
                if (e?.message === 'noPermission') {
                    this.$showErrorMessage(
                        '此帳號尚無查看此報表的權限',
                        '請確認您的帳號權限，如有疑問請洽飛鴿'
                    )
                } else {
                    this.$showErrorMessage(
                        '查詢時出現錯誤，請稍後再試',
                        '若持續出現此問題，請聯繫資訊客服'
                    )
                }
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterTitles() {
            return productReceiveFilterTitles
        }
    },
    data() {
        return {
            listData: [],
            isAllChecked: false,
            totalData: {},
            isTableLoading: false,
            filterPayload: {},
            isShowMobileAlert: false
        }
    },
    async created() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
    }
}
</script>

<style lang="scss" scoped>
.filter-bottom {
    position: relative;
    padding-bottom: 40px;
    .button {
        position: absolute;
        right: 32px;
    }
}

.table-wrapper {
    :deep(.guide-container) {
        .pc-search-img,
        .mobile-search-img {
            display: none;
        }
        .mobile span {
            display: none;
        }
    }
}
</style>
